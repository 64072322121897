exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bayer-tsx": () => import("./../../../src/pages/bayer.tsx" /* webpackChunkName: "component---src-pages-bayer-tsx" */),
  "component---src-pages-en-bayer-tsx": () => import("./../../../src/pages/en/bayer.tsx" /* webpackChunkName: "component---src-pages-en-bayer-tsx" */),
  "component---src-pages-en-tsx": () => import("./../../../src/pages/en.tsx" /* webpackChunkName: "component---src-pages-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projeto-tsx": () => import("./../../../src/pages/projeto.tsx" /* webpackChunkName: "component---src-pages-projeto-tsx" */),
  "component---src-pages-pt-bayer-tsx": () => import("./../../../src/pages/pt/bayer.tsx" /* webpackChunkName: "component---src-pages-pt-bayer-tsx" */),
  "component---src-pages-pt-tsx": () => import("./../../../src/pages/pt.tsx" /* webpackChunkName: "component---src-pages-pt-tsx" */)
}

